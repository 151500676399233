import Head from "next/head";
import PublicPage from "../components/PublicPage";

import Image from "next/image";
// import { LeafSingle } from "@30p/ui/icons";

import MobileImage from "../assets/mobile-app.png";
import WebImage from "../assets/web-app.png";

import AppStore from "../assets/app-store.svg";
import PlayStore from "../assets/play-store.png";

import { motion } from "motion/react";
import { useThemeContextValues } from "@30p/ui/utils/Theme";
import clsx from "clsx";
export default function LandingPage() {
  const { theme } = useThemeContextValues();
  return (
    <>
      <Head>
        <title>Thirty Plants</title>
        <meta name="description" content="30 Plants" />
      </Head>
      <PublicPage>
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.5 }}
          className="max-w-4xl md:mx-24 md:my-24"
        >
          <h1 className="text-2xl font-light md:text-5xl">
            Track the variety of your diet while aiming to eat{" "}
            <span className="decoration-primary font-bold underline">
              Thirty Plants{" "}
            </span>
            every week.
          </h1>

          <p className="my-12 max-w-3xl  md:text-xl">
            <span className="font-light">
              A simple way of keeping tabs on your plant intake, without the
              stress of calorie-counting and other hassle.
            </span>
          </p>

          <div className="mb-12 flex items-center justify-center">
            <a href="https://apps.apple.com/il/app/thirty-plants/id6447438431">
              <Image height={49} src={AppStore} alt="App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=thirtyplants.app">
              <Image height={72} src={PlayStore} alt="Play Store" />
            </a>
          </div>

          <div className="flex justify-center gap-4 md:-mx-8">
            <div className="w-[70vw] md:w-[25%]">
              <p className="mb-2 text-sm">
                iOS & Android{theme !== "legacy" && " (Color Scheme WIP)"}
              </p>
              <Image
                className={clsx(
                  "rounded-lg border-2 border-black grayscale",
                  theme === "legacy" && "grayscale-0",
                  theme === "dark" && "border-white invert",
                )}
                src={MobileImage}
                alt="Mobile App"
              />
            </div>
            <div className="hidden w-[77.84%] md:block">
              <p className="mb-2 text-sm">
                Web App{theme !== "legacy" && " (Color Scheme WIP)"}
              </p>

              <Image
                className={clsx(
                  "rounded-lg border-2 border-black grayscale",
                  theme === "legacy" && "grayscale-0",
                  theme === "dark" && "border-white invert",
                )}
                src={WebImage}
                alt="Web App"
              />
            </div>
          </div>
        </motion.div>
      </PublicPage>
    </>
  );
}
