import Link from "next/link";
import clsx from "clsx";
import Icon from "@30p/ui/icons";
import { useRouter } from "next/router";
import TopNav from "./TopNav";

export default function PublicPage(props: { children: React.ReactNode }) {
  return (
    <div className="flex h-screen flex-col items-center overflow-auto  pt-16">
      <TopNav />
      <main className="mx-8 my-12 flex-1 bg-bg1 lg:min-w-[65ch]">
        {props.children}
      </main>
      <footer className="w-full bg-bg2 px-4 py-4">
        <div className="mx-auto flex max-w-5xl text-xs">
          <div className="mr-auto flex flex-col gap-2">
            <div className="my-auto flex flex-row items-center gap-2 text-text">
              Created by{" "}
              <Link
                href="https://www.vibey.co.uk"
                className="font-bold transition-colors hover:text-primary hover:underline"
              >
                Fred Shepherd
              </Link>
              <Icon icon="LeafSingle" size={24} color="text" />
            </div>
            <div className="flex gap-2">
              {/* <Icon icon="Trophy" size={24} color="text" /> */}
            </div>
          </div>
          <div className="flex gap-12">
            <LinkColumn
              title={{ text: "Contact", href: "/contact" }}
              links={[
                {
                  text: "Email",
                  href: "mailto:fred@vibey.co.uk",
                },
                {
                  text: "Discord",
                  href: "https://discord.gg/rn7qBkRfgB",
                },
              ]}
            />
            <LinkColumn
              title={{ text: "Legal", href: "/legal" }}
              links={[
                {
                  text: "Privacy Policy",
                  href: "/legal/privacy-policy",
                },
                {
                  text: "Terms of Service",
                  href: "/legal/terms-of-service",
                },
              ]}
            />
            <LinkColumn
              title={{ text: "Resources", href: "/resources" }}
              links={[
                {
                  text: "FAQs",
                  href: "/resources/frequently-asked-questions",
                },
                {
                  text: "Developer Blog",
                  href: "/resources/developer-blog",
                },
                {
                  text: "Roadmap",
                  href: "/resources/roadmap",
                },
              ]}
            />
          </div>
        </div>
      </footer>
    </div>
  );
}

function LinkColumn(props: {
  title: { text: string; href: string };
  links: { text: string; href: string }[];
}) {
  const pathname = useRouter().pathname;
  return (
    <div className="flex flex-col">
      <Link href={props.title.href}>
        <div className="font-semibold text-text/50 hover:underline">
          {props.title.text}
        </div>
      </Link>
      {props.links.map((link) => (
        <Link
          key={link.href}
          href={link.href}
          className={clsx(
            "mr-auto flex items-center decoration-primary underline-offset-2 hover:underline",
            {
              underline: link.href === pathname,
            },
          )}
        >
          {/* <span className="mr-2 text-2xl">🌱</span> */}

          <span className="text font-light text-text decoration-text transition-colors hover:text-purple-300">
            {link.text}
          </span>
        </Link>
      ))}
    </div>
  );
}
