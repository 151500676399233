import { useAuth } from "@clerk/nextjs";
import Link from "next/link";
import clsx from "clsx";
import ClickableIcon from "@30p/ui/atoms/ClickableIcon";
import Icon from "@30p/ui/icons";
import { useState } from "react";
import { useRouter } from "next/router";
import Text from "@30p/ui/atoms/Text";
import Button from "@30p/ui/atoms/Button";

export default function TopNav() {
  const { isSignedIn, isLoaded } = useAuth();
  return (
    <nav className="fixed left-0 right-0 top-0 z-10 flex min-h-[74px] border-b-2 border-borderBg1 bg-bg1/75 px-4 backdrop-blur-sm">
      <div className="mx-auto flex max-w-5xl flex-1 items-center">
        <MobileNav />
        <DesktopNav />
        {isLoaded && (
          <div className="mb-auto ml-auto mt-4">
            {isSignedIn ? (
              <Link href="/dashboard">
                <Button color="primary" text="Dashboard" />
              </Link>
            ) : (
              <div className="flex items-center gap-4">
                <Link href="/sign-in">
                  <Text className="font-medium underline decoration-primary transition-colors hover:decoration-textInverted">
                    Sign in
                  </Text>
                </Link>
                <Link href="/sign-up">
                  <Button color="primary" text="Create your account" />
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
}

function MobileNav() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="transition-all md:hidden">
      <div className="py-4 ">
        {isOpen ? (
          <ClickableIcon
            icon="Close"
            size={32}
            onPress={() => setIsOpen(false)}
          />
        ) : (
          <ClickableIcon
            icon="Menu"
            size={32}
            onPress={() => setIsOpen(true)}
          />
        )}
      </div>
      <div
        className={clsx("overflow-hidden transition-all", {
          "h-[100vh]": isOpen,
          "h-[0px] ": !isOpen,
        })}
      >
        <div className="px-2 py-2 text-2xl">
          <NavLink light href="/">
            Home
          </NavLink>
          <LinkColumn
            title={{ text: "Contact", href: "/contact" }}
            links={[
              {
                text: "Email",
                href: "/contact",
              },
              {
                text: "Discord",
                href: "https://discord.gg/rn7qBkRfgB",
              },
            ]}
          />
          <LinkColumn
            title={{ text: "Legal", href: "/legal" }}
            links={[
              {
                text: "Privacy Policy",
                href: "/legal/privacy-policy",
              },
              {
                text: "Terms of Service",
                href: "/legal/terms-of-service",
              },
            ]}
          />
          <LinkColumn
            title={{ text: "Resources", href: "/resources" }}
            links={[
              {
                text: "FAQs",
                href: "/resources/frequently-asked-questions",
              },
              {
                text: "Developer Blog",
                href: "/resources/developer-blog",
              },
              {
                text: "Roadmap",
                href: "/resources/roadmap",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

function DesktopNav() {
  return (
    <div className="mr-auto hidden items-center gap-5 md:flex">
      <NavLink href="/">
        <Icon icon="LeafSingle" size={32} />
      </NavLink>

      <NavLink href="/">
        <Text>Thirty Plants</Text>
      </NavLink>
      <NavLink href="/resources/announcements">
        <Text>Announcements</Text>
      </NavLink>
      <NavLink href="/resources/frequently-asked-questions">
        <Text>FAQs</Text>
      </NavLink>
      {/* <NavLink href="/legal/privacy-policy">Privacy Policy</NavLink> */}
      {/* <NavLink href="/resources/developer-blog">Developer Blog</NavLink> */}

      <NavLink href="https://discord.gg/rn7qBkRfgB">
        <Text>Discord</Text>
      </NavLink>
    </div>
  );
}
function NavLink(props: {
  href: string;
  children: React.ReactNode;
  underline?: boolean;
  light?: boolean;
}) {
  const pathname = useRouter().pathname;
  return (
    <Link href={props.href} className="mr-auto flex items-center">
      {/* <span className="mr-2 text-2xl">🌱</span> */}
      <span
        className={clsx(
          "text decoration-primary underline-offset-2 transition-colors hover:decoration-textInverted",
          {
            underline: props.underline || props.href === pathname,
            "font-light": props.light,
            "font-medium": !props.light,
          },
        )}
      >
        {props.children}
      </span>
    </Link>
  );
}

function LinkColumn(props: {
  title: { text: string; href: string };
  links: { text: string; href: string }[];
}) {
  const pathname = useRouter().pathname;
  return (
    <div className="flex flex-col">
      <Link href={props.title.href}>
        <div className="font-light text-gray-400">{props.title.text}</div>
      </Link>
      {props.links.map((link) => (
        <Link
          key={link.href}
          href={link.href}
          className={clsx(
            "mr-auto flex items-center decoration-green-300 underline-offset-2",
            {
              underline: link.href === pathname,
            },
          )}
        >
          {/* <span className="mr-2 text-2xl">🌱</span> */}
          <span className={clsx("text font-light decoration-white", {})}>
            {link.text}
          </span>
        </Link>
      ))}
    </div>
  );
}
